import { OldDom, jsonTryDecode, dlpagejson, shoutboxtime, lowname, findParentMatch } from "@runeapps/common/oldlib";
import { ShoutBox } from "./homepage";
import { PageAddress, PageContent } from "../../homepagetypes";
import "../../homepagedeclares";
import { posmod } from "@runeapps/common/util";

var elid = OldDom.id;
var toggleclass = OldDom.toggleclass;
var eldiv = OldDom.div;
var elfrag = OldDom.frag;
var elput = OldDom.put;
var elcl = OldDom.cl;

var lastpage: PageAddress | null = null;

var subpagehandlers: { [path: string]: "passive" | "active" } = {
	alt1: "passive",
	player: "active",
	hiscores: "active",
	bosses: "active"
};

var extrascripts: { [src: string]: { el: HTMLScriptElement; loaded: boolean; } } = {};
var extrastylesheets: { [src: string]: { el: HTMLStyleElement; loaded: boolean; } } = {};
var hoveredHeaderSection: HTMLElement | null = null;



(function () {
	//add already loaded scripts to the script cache
	var scripts = document.getElementsByTagName("script");
	for (var a = 0; a < scripts.length; a++) {
		extrascripts[scripts[a].src] = { el: scripts[a], loaded: true };
	}
	//add already loaded stylesheets to the stylesheet cache
	var links = document.getElementsByTagName("link");
	for (var a = 0; a < links.length; a++) {
		if (links[a].rel == "stylesheet") {
			extrastylesheets[links[a].href] = { el: links[a], loaded: true };
		}
	}

	window.addEventListener("popstate", locationchange);
	document.addEventListener("click", urlclick);

	var hoverevent = (e: MouseEvent) => {
		hoveredHeaderSection = (e.type == "mouseenter" ? e.currentTarget as HTMLElement : null);
		fixHeader();
	}

	window.addEventListener('DOMContentLoaded', e => {
		elementsByCss(".mb-section").forEach(s => s.onmouseenter = hoverevent);
		elementsByCss(".mb-root").forEach(s => s.onmouseleave = hoverevent);
		fixHeader();
	});

	//google analytics
	if (window == top) { setTimeout(() => sendGA(), 1000); }
})();

function startGA() {
	if (!window._gaq && (document.location.host == "runeapps.org" || window.testGA)) {
		window._gaq = window._gaq || [];
		_gaq.push(['_setAccount', 'UA-29246134-1']);
		(function () {
			var ga = document.createElement('script');
			ga.type = 'text/javascript';
			ga.async = true;
			ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'https://www') + '.google-analytics.com/ga.js';
			var s = document.getElementsByTagName('script')[0];
			s.parentNode!.insertBefore(ga, s);
		})();
	}
}

export function sendGA(page?: string) {
	if (document.location.host == "runeapps.org" || window.testGA) {
		startGA();
		if (!page) { _gaq.push(["_trackPageview"]); }
		else { _gaq.push(['_trackPageview', page]) }
	}
}

export function closeFocus() {
	let page = pages[currentpage.srcfile];
	if (!page || page == "loading") { throw new Error("unknown parent page"); }
	if (lastpage && lastpage.srcfile == page.parentpage!) {
		history.back();
	} else {
		setUrl(page.parentpage!);
	}
}

export function setUrl(url: string, nohist?: boolean) {
	var page = getpagename(url + "");
	if (!page || getpage(page, nohist)) {
		document.location.href = url;
	}
}

function urlclick(e: MouseEvent, nohist?: boolean) {
	if (e.button == 1) {
		//ignore middle mouse button (newtab)
		return;
	}
	var target = e.target as HTMLElement | null;
	var anch = null as HTMLAnchorElement | null;
	while (target) {
		if (target.tagName == "A") {
			anch = target as any;
			break;
		}
		target = target.parentElement;
	}
	if (anch && anch.href) {
		if (anch.origin != document.location.origin) { return; }
		if (anch.getAttribute("download") !== null) { return; }
		if (anch.getAttribute("onclick") !== null) { return; }
		if (anch.getAttribute("target") !== null) { return; }
		var page = getpagename(anch.href);
		if (page && !getpage(page, nohist)) {
			e.preventDefault();
		}
	}
}

function replacearg(str: string, address: PageAddress) {
	let getargstr = (i: number) => lowname(address.parts[i] || "");
	return str.replace(/\{([:\w =]+)\}/g, (_, qstr: string) => {
		let qargs = qstr.split(/:/g);
		let method = qargs.shift()!;
		if (method == "argpretty") {
			//{argpretty:[argid]}
			let arg = getargstr(+qargs.shift()!);
			return arg.replace(/[_\-+]/g, " ").replace(/\b\w/g, m => m.toUpperCase());
		}
		if (method == "arg" || method == "argword") {
			//{arg:[argid]}
			return getargstr(+qargs.shift()!);
		}
		if (method == "argenum") {
			//{argenum:[argid]:[defaultstr]:([urlmatch]=[str])*}
			let argstr = getargstr(+qargs.shift()!);
			let fallback = qargs.shift() || "";
			let arg: string | undefined;
			while (arg = qargs.shift()) {
				let parts = arg.split("=");
				if (parts[0] == argstr) { return parts[1]; }
			}
			return fallback;
		}
		return "";
	});
}

function elementsByCss(selector: string) {
	var els = document.querySelectorAll(selector);
	var res: HTMLElement[] = [];
	for (let a = 0; a < els.length; a++) { res.push(els[a] as any); }
	return res;
}

export function fixHeader() {
	var addr = currentpage;
	var pageprogress = pages[addr.srcfile];
	//TODO change how this works?
	if (pageprogress == "loading") { return; }

	//need to rename for ts
	let pagemeta = pageprogress;

	var subsection: HTMLElement | null = null;
	var els = elementsByCss("[data-route]");
	outer: for (let el of els) {
		let page = el.getAttribute("data-route")!;
		let routepage = getpagename(page)!;
		let samesrc = addr.srcfile == routepage.srcfile;
		let matchpage = samesrc;
		for (let a in routepage.parts) {
			if (routepage.parts[a] == "*") {
				if (samesrc) {
					routepage.parts[a] = addr.parts[a] || "";
				} else {
					routepage.parts[a] = "";
				}
			} else if (samesrc && (addr.parts[a] || "") != routepage.parts[a]) {
				matchpage = false;
			}
		}
		let href = buildpageurl(routepage);
		el.setAttribute("href", href);
		if (matchpage) { subsection = el; }
	}

	var section = findParentMatch(hoveredHeaderSection || subsection, ".mb-section");

	//subgroup = subgroup || elid("mb-group-placeholder");
	elementsByCss(".mb-section").forEach(el => toggleclass(el, "mb-section--selected", el == section));
	elementsByCss(".mb-subsection").forEach(el => toggleclass(el, "mb-subsection--selected", el == subsection));

	var newstyle = true;//pagemeta.newstyleframe;
	elementsByCss(".mb-spacer").forEach(el => el.style.display = newstyle ? "" : "none");
	elementsByCss(".mb-root").forEach(el => el.style.display = newstyle ? "" : "none");
	elementsByCss("#header").forEach(el => el.style.display = newstyle ? "none" : "");
}

//we accumolated some weirdness with pages wishing to change the url while inside the onload callback, queue the attempts
//instead and trigger them in the same tick
var getpagelock = false;
var getpagenext: { newpage: PageAddress, nohist?: boolean } | null = null;
var pendingseturl: { url: string, title: string, pushhist: boolean } | null = null;
export function getpage(newpage: PageAddress, nohist?: boolean) {
	if (getpagelock) {
		getpagenext = { newpage, nohist };
		let frompage = getCurrentPageAddress();
		console.log(`queued  getpage ${pagenamedebug(frompage)} to ${pagenamedebug(newpage)} ${nohist ? "nohist" : ""}`);
		return false;
	}
	getpagelock = true;
	try {
		let frompage = getCurrentPageAddress();
		console.log(`running getpage ${pagenamedebug(frompage)} to ${pagenamedebug(newpage)} ${nohist ? "nohist" : ""}`);
		return getpageinner(newpage, nohist);
	} finally {
		getpagelock = false;
		if (getpagenext) {
			var tmp = getpagenext;
			getpagenext = null;
			let frompage = getCurrentPageAddress();
			console.log(`stalled getpage ${pagenamedebug(frompage)} to ${pagenamedebug(tmp.newpage)} ${tmp.nohist ? "nohist" : ""}`);
			getpage(tmp.newpage, tmp.nohist);
		}
		if (!getpagenext) {
			commitsetpagename();
		}
	}
}
export function getpageinner(newpage: PageAddress, nohist?: boolean) {
	var pagemeta = pages[currentpage.srcfile];
	var newpagemeta = pages[newpage.srcfile];
	//==make sure we have all data==
	if (newpagemeta == "loading") {
		return false;
	}
	if (!newpagemeta) {
		pages[newpage.srcfile] = "loading";
		downloadpage(newpage.srcfile, function () { getpage(newpage, nohist); }, function () { document.location.href = buildpageurl(newpage) });
		return false;
	}
	var missingscripts = false;
	if (newpagemeta.scripts) {
		for (var a = 0; a < newpagemeta.scripts.length; a++) {
			var b = document.createElement("a");
			b.href = newpagemeta.scripts[a];
			var normsrc = b.href;//normalize the src string
			if (!extrascripts[normsrc]) {
				var el = document.createElement("script");
				el.src = normsrc;
				var entry = { el: el, loaded: false };
				el.onload = (function (entry) {
					entry.loaded = true;
					getpage(newpage, nohist);
				}).bind(null, entry);
				document.head.appendChild(el);
				extrascripts[normsrc] = entry;
				missingscripts = true;
			} else if (!extrascripts[normsrc].loaded) {
				missingscripts = true;
			}
		}
	}
	if (newpagemeta.stylesheets) {
		for (var a = 0; a < newpagemeta.stylesheets.length; a++) {
			var b = document.createElement("a");
			b.href = newpagemeta.stylesheets[a];
			var normsrc = b.href;//normalize the src string
			if (!extrastylesheets[normsrc]) {
				var linkel = document.createElement("link");
				linkel.rel = "stylesheet";
				linkel.href = normsrc;
				var linkentry = { el: linkel, loaded: false };
				linkel.onload = (function (entry) {
					entry.loaded = true;
					getpage(newpage, nohist);
				}).bind(null, linkentry);
				document.head.appendChild(linkel);
				extrastylesheets[normsrc] = linkentry;
				missingscripts = true;
			} else if (!extrastylesheets[normsrc].loaded) {
				missingscripts = true;
			}
		}
	}
	if (missingscripts) {
		return false;
	}
	//TODO add loading indicator

	//==content change==
	var needreplace = !comparepagename(newpage, currentpage);
	var title = replacearg(newpagemeta.title, newpage);
	if (newpagemeta.module && !newpagemeta.moduleinst) {
		try {
			let fn = new Function("exports", newpagemeta.module + "\nreturn exports;");
			newpagemeta.moduleinst = fn({});
		}
		catch (b) {
			console.log("page js module load");
			needreplace = true;
		}
	}

	elid("maincontenttitle").innerText = title;
	if (newpage.srcfile == currentpage.srcfile) {
		if (needreplace && newpagemeta.moduleinst && newpagemeta.moduleinst.queryChange) { //try handle arg change using content script
			try {
				newpagemeta.moduleinst.queryChange(newpage);
				needreplace = false;
			}
			catch (b) {
				console.log("querychange failed");
				needreplace = true;
			}
		}
	}
	if (needreplace) {
		if (pagemeta != "loading" && pagemeta.moduleinst && pagemeta.moduleinst.unload) {
			try { pagemeta.moduleinst.unload(); }
			catch (e) { }
		}
	}

	//unload event
	setpagename(newpage, title || "RuneApps", nohist);
	if (needreplace) {
		console.log("== maincontent " + newpage.srcfile + " ==");
		elid("content-default").innerHTML = (newpagemeta.pagemode == "default" ? newpagemeta.content : "");
		elid("content-app").innerHTML = (newpagemeta.pagemode == "app" ? newpagemeta.content : "");
		elid("content-noframe").innerHTML = (newpagemeta.pagemode == "noframe" ? newpagemeta.content : "");
		elid("content-fixed").innerHTML = (newpagemeta.pagemode == "fixed" ? newpagemeta.content : "");
		document.body.setAttribute("data-pagemode", newpagemeta.pagemode);

		window.scrollTo(0, Math.min(window.pageYOffset, 100));
		if (newpagemeta.moduleinst && newpagemeta.moduleinst.load) {
			try {
				newpagemeta.moduleinst.load(newpage);
			} catch (b) {
				console.log("Page onload failed");
			}
		}
	}
	if (newpagemeta.parentpage && newpagemeta.parentpage != "") {
		elid("mainclosebutton").style.display = "block";
	} else {
		elid("mainclosebutton").style.display = "none";
	}

	//==done==
	settheme(newpagemeta.theme);
	fixHeader();
	return false;
}

function comparepagename(pagea: PageAddress, pageb: PageAddress) {
	if (pagea.srcfile != pageb.srcfile) { return false; }
	if (pagea.parts.length != pageb.parts.length) { return false; }
	for (var a = 0; a < pagea.parts.length; a++) {
		if (pagea.parts[a].toLowerCase().replace(/\+/g, "_") != pageb.parts[a].toLocaleLowerCase().replace(/\+/g, "_")) {
			return false;
		}
	}
	return true;
}

function pagenamedebug(page: PageAddress) {
	return [`[${page.srcfile}]`, ...page.parts].join("/");
}

function setpagename(nameobj: PageAddress, title: string, replace = false) {
	var difurl = !comparepagename(nameobj, currentpage);
	if (!replace) { lastpage = currentpage; }
	currentpage = nameobj;
	pendingseturl = { url: "/" + buildpageurl(nameobj), title, pushhist: pendingseturl?.pushhist || (difurl && !replace) };
}

function commitsetpagename() {
	if (pendingseturl) {
		if (pendingseturl.pushhist) { history.pushState("", "", pendingseturl.url); }
		else { history.replaceState("", "", pendingseturl.url); }
		document.title = pendingseturl.title;
	}
	pendingseturl = null;
	sendGA(document.location + "");
}

function downloadpage(name: string, callback?: () => any, errcb?: () => any) {
	if (pages[name] && pages[name] != "loading") {
		//TODO not getting their callback
		console.warn("callback of downloadpage skipped by bug");
		return;
	}
	dlpagejson<PageContent>("content/" + name.replace(/\//g, "__") + ".html", null, function (res) {
		pages[name] = res;
		callback && callback();
	}, errcb);
}

function rootrelurl(url: string) {
	url = url + "";
	url = url.replace(/#.*$/, "");
	var base = document.location.origin;
	if (url.match(/^(https?|data|blob):/)) {
		if (url.indexOf(base) != 0) { throw new Error("url is not relative to base"); }
		url = url.slice(base.length);
	}
	if (url[0] == "/") { url = url.slice(1); }
	return url;
}

export function buildpageurl(page: PageAddress) {
	if (page.srcfile == "home" && page.parts.length == 0) { return ""; }
	return page.srcfile + (page.parts.length == 0 ? "" : "/" + page.parts.join("/"));
}

export function getCurrentPageAddress() {
	var url = pendingseturl?.url || document.location.pathname;
	return getpagename(url);
}
export function getpagename(url: string) {
	var relurl = rootrelurl(url);
	var path = relurl.split("/").map(p => decodeURIComponent(p).replace(/(_|\+|\-|%20| )/g, "_"));
	var subpath = path.slice(1);
	var totalbase = path[0];
	while (true) {
		var handler = subpagehandlers[totalbase];
		if (subpath.length == 0 || handler == "active") {
			return { parts: subpath, srcfile: totalbase || "home" } as PageAddress;
		}

		//next iteration
		var base = subpath[0];
		if (base.match(/\W/)) { throw new Error("invalid url, non word part in base url"); }
		totalbase += "/" + base;
		subpath = subpath.slice(1);
	}
}

export function locationchange() {
	var addres = getpagename(document.location.href);
	if (addres) { getpage(addres, true); }
}

//TODO manage the default better
function settheme(themeid = "cwapp") {
	var currentback = document.querySelector(".backshuffle.current") as HTMLElement;
	var newbackurl = "url('" + themes[themeid].back + "')";
	//chrome rewrites single quotes to double in getter
	//just go with it for now...
	var oldurl = (currentback ? currentback.style.backgroundImage.replace(/"/g, "'") : "");
	if (oldurl != newbackurl) {
		var img = new Image();
		var loaded = function (noimg) {
			//need to copy it because query auto updates otherwise and has broke iterator
			var backs: Element[] = [];
			var backsquery = document.getElementsByClassName("backshuffle");
			for (let a = 0; a < backsquery.length; a++) { backs.push(backsquery[a]); }

			for (var f in backs) {
				backs[f].classList.remove("current");
			}

			var newback = document.createElement("div");
			newback.className = "backshuffle";
			if (noimg === true) { newback.style.backgroundImage = newbackurl }//set background without loading, this will load when window is resized and css doesnt overwrite anymore
			else { newback.style.backgroundImage = newbackurl; }
			newback.style.opacity = "0";
			newback.style.backgroundColor = themes[themeid].backcolor;
			newback.classList.add("current");
			elid("backgrounddiv").appendChild(newback);
			//needs more time on chrome to realise the opacity is changed after adding the node
			setTimeout(function () { newback.style.opacity = "1"; }, 100);
			setTimeout(function () {
				for (var f in backs) {
					if (backs[f].parentElement) { backs[f].parentElement!.removeChild(backs[f]); }
				}
			}, 3000);
		};
		img.onerror = function () {
			img.onerror = function () { console.log("failed to load background and fallback"); }
			loaded(true);
		}
		img.onload = loaded;
		if (window.innerWidth > 800) { img.src = themes[themeid].back; }
		else { loaded(true); }
	}
}