import { OldDom, jsonTryDecode, dlpagejson, shoutboxtime, lowname, findParentMatch } from "@runeapps/common/oldlib";
import { ShoutBox } from "./homepage";
import { PageAddress, PageContent } from "../../homepagetypes";
import { posmod } from "@runeapps/common/util";

var elid = OldDom.id;
var toggleclass = OldDom.toggleclass;
var eldiv = OldDom.div;
var elfrag = OldDom.frag;
var elput = OldDom.put;
var elcl = OldDom.cl;

declare global {
	var _gaq: any[];
	var testGA: boolean;
}

export function ppAutoComplete(el: HTMLElement) {
	let id = "autocomplete-" + Math.random();
	let datalist = eldiv(":datalist", { id: id });
	let updatelist = () => {
		var pcache: {name:string}[] | null = jsonTryDecode(localStorage.pp_playercache);
		let frag = elfrag(...(!pcache ? [] : pcache.map(p => eldiv(":option", [p.name]))));
		elput(datalist, frag);
	}
	updatelist();
	el.setAttribute("list", id);
	el.insertAdjacentElement("afterend", datalist);
}

export var ImgViewer = new class {
	currentzoomedimg: HTMLElement | null = null;
	currentzoomedlist: { src: string, width: number, height: number, descr?: string, title?: string }[] = [];
	currentzoomedindex = 0;
	zoomimg(origin: HTMLImageElement | "left" | "right", imglist: typeof ImgViewer.currentzoomedlist, index: number) {
		this.exitimgzoom();

		if (imglist && typeof origin != "string") {
			let a = origin.getAttribute("src");
			for (let b in imglist) { if (a == imglist[b].src) { index = +b; break; } }
		}
		if (imglist) {
			var imgtitle = imglist[index].title;
			var imgdescr = imglist[index].descr;
		}

		if (origin instanceof HTMLElement) {
			var w = origin.naturalWidth;
			var h = origin.naturalHeight;
			this.currentzoomedimg = origin;
			var imgsrc = origin.src;
		}
		else {
			w = imglist[index].width;
			h = imglist[index].height;
			imgsrc = imglist[index].src;
		}

		if (imglist) {
			this.currentzoomedlist = imglist;
			this.currentzoomedindex = index;
		}

		//calculate starting bounds
		var bounds: { left: number, top: number, width: number, height: number } | null = (typeof origin != "string" ? origin.getBoundingClientRect() : null);
		if (!bounds) {
			if (origin == "right") { bounds = { left: -w / 2, top: window.innerHeight / 2 - h / 4, width: w / 2, height: h / 2 }; }
			else { bounds = { left: window.innerWidth, top: window.innerHeight / 2 - h / 4, width: w / 2, height: h / 2 }; }
		}
		//calculate zoomed size
		var a: any;
		a = Math.min(window.innerWidth / w, window.innerHeight / h) * 0.8;
		if (a < 1) { w *= a; h *= a; }
		var l = (window.innerWidth - w) / 2;
		var t = (window.innerHeight - h) / 2;

		//create/find outer element
		var r = elid("zoomedimgouter");
		var newouter = false;
		if (!r) {
			newouter = true;
			r = document.createElement("div");
			r.id = "zoomedimgouter";
			r.addEventListener("click", () => this.exitimgzoom());
			document.body.appendChild(r);
			r.style.background = "rgba(0,0,0,0)";
		}

		//create other stuff
		if (newouter) {
			var str = "";
			str += "<div id='zoomedimgtitle'></div>";
			str += "<div id='zoomedimgdescr'></div>";
			if (imglist) {
				str += "<div id='zoomedimgleft'></div>";
				str += "<div id='zoomedimgright'></div>";
			}
			r.innerHTML = str;
			if (imglist) {
				elid("zoomedimgright").addEventListener("click", e => { this.zoomimgshift(1); e.stopPropagation(); });
				elid("zoomedimgleft").addEventListener("click", e => { this.zoomimgshift(-1); e.stopPropagation(); });
			}
		}
		if (a = elid("zoomedimgright")) {
			a.style.left = (l + w - w / 4) + "px"; a.style.top = t + "px"; a.style.height = h + "px"; a.style.width = w / 4 + "px";
			a.style.display = "none";
		}
		if (a = elid("zoomedimgleft")) {
			a.style.left = l + "px"; a.style.top = t + "px"; a.style.height = h + "px"; a.style.width = w / 4 + "px";
			a.style.display = "none";
		}
		elid("zoomedimgtitle").innerHTML = imgtitle || "";
		elid("zoomedimgtitle").style.display = "none";
		elid("zoomedimgtitle").style.opacity = "" + 0;
		elid("zoomedimgtitle").style.left = l + "px";
		elid("zoomedimgtitle").style.width = w + "px";
		elid("zoomedimgtitle").style.top = t + "px";

		elid("zoomedimgdescr").innerHTML = imgdescr || "";
		elid("zoomedimgdescr").style.display = "none";
		elid("zoomedimgdescr").style.opacity = "" + 0;
		elid("zoomedimgdescr").style.left = l + "px";
		elid("zoomedimgdescr").style.width = w + "px";
		elid("zoomedimgdescr").style.top = (t + h) + "px";


		//create img element
		var elimg = document.createElement("img");
		r.appendChild(elimg);
		elimg.outerHTML = "<img class='zoomedimg' id='zoomedimg' style='width:" + bounds.width + "px; height:" + bounds.height + "px; left:" + bounds.left + "px; top:" + bounds.top + "px;' src='" + imgsrc + "'/>";

		setTimeout(function () {
			elid("zoomedimgouter").style.background = "rgba(0,0,0,0.5)";
			var el = elid("zoomedimg");
			el.style.left = l + "px";
			el.style.top = t + "px";
			el.style.width = w + "px";
			el.style.height = h + "px";
		}, 20);
		setTimeout(function () {
			if (imgtitle) { elid("zoomedimgtitle").style.display = "block"; }
			if (imgdescr) { elid("zoomedimgdescr").style.display = "block"; }
			if (a = elid("zoomedimgleft")) { a.style.display = "block"; }
			if (a = elid("zoomedimgright")) { a.style.display = "block"; }
		}, 500);
		setTimeout(function () {
			elid("zoomedimgtitle").style.opacity = "1";
			elid("zoomedimgdescr").style.opacity = "1";
		}, 520);
	}
	zoomimgshift(dif: number) {
		if (this.currentzoomedlist.length < 2) { return; }

		var newi = posmod(this.currentzoomedindex + dif, this.currentzoomedlist.length);

		this.exitimgzoom((dif < 0 ? "left" : "right"));
		this.zoomimg((dif > 0 ? "left" : "right"), this.currentzoomedlist, newi);
	}

	exitimgzoom(dir?: "left" | "right") {
		if (!elid("zoomedimg")) { return; }

		var el = elid("zoomedimg");
		el.id = "zoomedoldimg";
		var originalbounds = !dir && this.currentzoomedimg && this.currentzoomedimg.getBoundingClientRect && this.currentzoomedimg.getBoundingClientRect();

		//determine end positions
		var bounds = el.getBoundingClientRect();
		if (originalbounds) {
			el.style.left = originalbounds.left + "px";
			el.style.top = originalbounds.top + "px";
			el.style.width = originalbounds.width + "px";
			el.style.height = originalbounds.height + "px";
		}
		else {
			el.style.height = bounds.height / 2 + "px";
			el.style.width = bounds.width / 2 + "px";
			el.style.top = (bounds.top + bounds.height / 4) + "px";
			if (dir == "left") {
				el.style.left = window.innerWidth + "px";
			}
			else {
				el.style.left = -bounds.width + "px";
			}
		}
		//hide interfaces
		let a: any;
		if (a = elid("zoomedimgright")) { a.style.display = "none"; }
		if (a = elid("zoomedimgleft")) { a.style.display = "none"; }
		elid("zoomedimgtitle").style.display = "none";
		elid("zoomedimgtitle").style.opacity = "0";
		elid("zoomedimgdescr").style.display = "none";
		elid("zoomedimgdescr").style.opacity = "0";

		//image zoom ended if no dir given
		if (!dir) {
			elid("zoomedimgouter").style.background = "rgba(0,0,0,0)";
			setTimeout(function () { elid("zoomedimgouter").parentNode!.removeChild(elid("zoomedimgouter")); }, 500);
		}
		else {
			setTimeout(function () { elid("zoomedoldimg").parentNode!.removeChild(elid("zoomedoldimg")); }, 500);
		}
		this.currentzoomedimg = null;
	}

}

//TODO move this and a couple of other content functions to a different file
export function registerFancyButton(elarg: HTMLElement | string) {
	var el = (typeof elarg == "string" ? elid(elarg) : elarg);
	var backstr = el.style.background;
	var brightness = el.getAttribute("data-hoverrgb") || "255,255,255";
	var hover = function (e?: MouseEvent) {
		var str = "";
		if (e) { str += "radial-gradient(100px at " + e.offsetX + "px " + (e.offsetY - el.offsetHeight / 2) + "px, rgba(" + brightness + ",0.3), rgba(" + brightness + ",0.1)),"; }
		str += backstr;
		el.style.background = str;
	};
	el.addEventListener("mousemove", hover);
	el.addEventListener("mouseleave", function () { hover(); });
}
