import { OldDom, jsonTryDecode, dlpagejson, shoutboxtime, lowname } from "@runeapps/common/oldlib";

export * from "./pagemanager";
export * from "./contentutil";

require("!file-loader?name=[path]runeappslib.css!sass-loader!./runeappslib.scss");
require("!file-loader?name=[path]style.css!sass-loader!./style.scss");
require("!file-loader?name=[path]darkmode.css!sass-loader!./darkmode.scss");
require("!file-loader?name=[path]contentstyle.css!sass-loader!./contentstyle.scss");


var elid = OldDom.id;
var toggleclass = OldDom.toggleclass;
var eldiv = OldDom.div;
var elfrag = OldDom.frag;
var elput = OldDom.put;
var elcl = OldDom.cl;

type ShoutboxLine = {
	time: number,
	logstatus: number,
	message: string,
	name: string
}

class ShoutboxElement {
	loaded = false;
	ws: WebSocket | null = null;

	lastcontent: ShoutboxLine[] = [];
	load() {
		dlpagejson("/node/shoutbox/messages", null, (r) => {
			this.lastcontent = r.content;
			this.draw();
		});
	}
	send(name: string, mes: string) {
		dlpagejson("/node/shoutbox/messages", { name: name, message: mes }, (r) => {
			if (r.error) {
				//TODO
				//balloonbox("Error: " + r.error, { el: elid("shoutboxinput"), side: 2, w: 200 }, [['Close']]);
				return;
			}
			this.lastcontent = r.content;
			this.draw();
		});
	}

	modcrown() {
		return eldiv({
			title: "Moderator",
			style: 'width:12px; height:12px; background:url("/imgs/modcrown.png"); margin-right:3px; float:left; margin-top:2px;'
		});
	}

	draw() {
		this.lastcontent = this.lastcontent.sort(function (a, b) { return a.time - b.time; });
		var lines = this.lastcontent;
		var frag = elfrag();
		for (var a = 0; a < lines.length; a++) {
			var line = lines[a];

			var parsed: (Element | string)[] = [];
			var reg = new RegExp("([\\s\\S]*?)\\b(https?:\\/\\/" + document.location.host + "\\/([\\w\\/\\.?&%=#]*[\\w\\/])?)", "g");
			var end = line.message.replace(reg, function (f, pre, full, path) {
				parsed.push(pre);
				parsed.push(eldiv(":a", { href: "/" + (path || "") }, [full]));
				return "";
			});
			parsed.push(end);

			frag.appendChild(eldiv("shoutboxline", [
				eldiv("shoutboxname", [(line.logstatus == 2 ? this.modcrown() : null), line.name]),
				eldiv("shoutboxmessage", parsed),
				eldiv("shoutboxtime", [shoutboxtime(line.time)])
			]));
		}

		if (frag.children.length == 0) { frag.appendChild(eldiv(["No messages yet."])); }
		var box = elid("shoutboxcontent");
		var doscroll = (box.scrollHeight - box.scrollTop - box.clientHeight < 20 || !this.loaded);
		elput(box, frag);
		if (!this.loaded && box.clientHeight > 10) {
			this.loaded = true;
		}
		if (doscroll) { this.scroll(); }
	}

	scroll() {
		var b = elid("shoutboxcontent");
		b.scrollTop = b.scrollHeight - b.clientHeight;
	}

	enter(el) {
		var nickel = el.querySelector("#shoutboxnick");
		var mesel = el.querySelector("#shoutboxinput");

		if (nickel.value == "") {
			//TODO
			//balloonbox("Please enter a valid name.", { el: nickel, side: 2, w: 200 }, [['Close']]);
			return;
		}
		if (mesel.value == "") {
			//TODO
			//balloonbox("Please enter a valid message.", { el: mesel, side: 2, w: 200 }, [['Close']]);
			return;
		}

		this.send(nickel.value, mesel.value);
		mesel.value = "";
	}

	hidefaq() {
		elid("shoutboxfaq").style.display = "none";
		elid("shoutboxinput").style.display = "block";
		elid("shoutboxnamewrapper").style.display = "block";
		localStorage.shoutbox_faqseen = "true";
	}

	connect(cb) {
		if (this.ws) { return; }
		this.ws = new WebSocket((document.location.protocol == "https:" ? "wss" : "ws") + "://" + document.location.host + "/node/shoutbox/listen");
		this.ws.onmessage = (e) => {
			var mes = JSON.parse(e.data);
			this.lastcontent.push(mes);
			this.draw();
		}
		this.ws.onclose = (e) => {
			this.ws = null;
		}
		this.ws.onopen = () => {
			if (cb) { cb(this.ws); }
		}
	}
	disconnect() {
		if (this.ws) { this.ws.close(); }
	}

	init() {
		if (localStorage.shoutbox_faqseen == "true") {
			this.hidefaq();
		}
		//*
		this.connect(() => this.load());
		/*/
		ex.load();
		//*/
	}
}

export var ShoutBox = new ShoutboxElement();





